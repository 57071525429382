import {memo, useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {EditTable, PageLayout} from 'components';
import {Grid, Paper, Typography} from "@mui/material";
import {actions as reportManagementActions} from "../../../store/slices/reportSlices/reportManagementSlices/reportManagementSlice";
import {useLocation, useNavigate} from "react-router-dom";
import {RootState} from "../../../store";
import {GridRowParams, GridValueGetterParams} from "@mui/x-data-grid";
import CheckIcon from '@mui/icons-material/Check';
import moment from "moment";
import Goback from "../../../components/Goback/Goback";

function ReportManagementByCategoryPage() {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const categoryUuid = location.pathname.split('/')[2]
    const category = useSelector((state: RootState) => state.reportManagement.category);

    const handleDeleteCategoryByUuid = useCallback(
        (params: GridRowParams) => {
            dispatch(reportManagementActions.deleteReportFromCategoryByUuid({reportId: params.row.uuid, categoryUuid: categoryUuid}))
        },
        [dispatch],
    );

    const handleExecuteReportByUuid = useCallback(
        (params: GridRowParams) => {
            if(params.row.reportParameters.length > 0){
                navigate(`/run_report/${params.row.uuid}`, { state: {uuid: params.row.uuid, name: params.row.name} });
            }else {

                navigate(`/report_result_w_o_parameters/${encodeURIComponent(params.row.uuid) || ''}`, {state: params.row})
            }

        },
        [],
    );

    const handleEditReport = useCallback(
        (params: GridRowParams) => {
            navigate('/report_management', {state: {reportUuid: params.row.uuid, isEdit: true} });
        },
        [],
    );

    useEffect(() => {
        if (categoryUuid) {
            dispatch(reportManagementActions.getReportsFromCategoryByUuid({categoryUuid: categoryUuid}))
        }

    }, [dispatch, categoryUuid])

    const columns: any = [
        {
            field: 'name',
            headerName: 'Name',
            width: 150,
        },
        {
            field: 'isActive',
            headerName: 'Active',
            width: 150,
            type: 'boolean',
            renderCell: (params: GridValueGetterParams) => params.row.isActive ? <CheckIcon/> : '',
        },
        {
            field: 'createdAt',
            headerName: 'Created At',
            width: 200,
            valueGetter: (params: GridValueGetterParams) =>
                moment(params.row.createdAt).format('DD.MM.YYYY hh:mm:ss'),
        },
        {
            field: 'updatedAt',
            headerName: 'Updated At',
            width: 200,
            valueGetter: (params: GridValueGetterParams) =>
                moment(params.row.updatedAt).format('DD.MM.YYYY hh:mm:ss'),
        },
    ];

    return (
        <PageLayout title={'Category' + ': ' + category.name} fullWidth={true}>
            <Grid container flexDirection={'column'} spacing={2}>
                <Grid item>
                    <Goback text="Go Back" location={-1} />
                    <Paper>
                        <Grid item padding={'11px 22px 22px'}>
                            <Grid flexDirection={'row'} container spacing={6}>
                                <Grid item>
                                    <Typography fontSize={13} marginBottom='5px'>
                                        {"Name"}
                                    </Typography>
                                    <Typography fontWeight={900} fontSize={13} marginBottom='22px'>
                                        {category?.name}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography fontSize={13} marginBottom='5px'>
                                        {"Roles"}
                                    </Typography>
                                    <Grid container flexDirection={'row'}>
                                        <Grid item>
                                            <Typography fontWeight={900} fontSize={13} marginBottom='22px'>
                                                {category?.roles?.map((role: any) => role.name).join(', ')}
                                            </Typography>
                                        </Grid>

                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item>
                    <EditTable
                        rows={ category?.reports || [] }
                        columns={columns}
                        getRowId={(row) => row.uuid}
                        onDelete={handleDeleteCategoryByUuid}
                        onExecute={handleExecuteReportByUuid}
                        onAdd={() => {
                            dispatch(reportManagementActions.setReportForEdit({}))
                            navigate('/report_management', {state: {isEdit: false, category: categoryUuid} })
                        }}
                        onEdit={handleEditReport}
                        editColumnWidth={150}
                        sortingOrderDirection="desc"
                        sortingMode="server"
                        hideFooter
                    />
                </Grid>
            </Grid>
        </PageLayout>
    );
}

export default memo(ReportManagementByCategoryPage);
