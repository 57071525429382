import { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Filters, TextField } from 'components';
import { RootState } from 'store';
import { actions as pmOrderActions } from 'store/slices/projectManagerSlice';
import { actions as orderLinesActions } from 'store/slices/orderLinesSlice';
import moment from 'moment';
import { Box, FormLabel, Grid } from '@mui/material';
import { daysOverdueDropdown } from 'config/utilsFunctions';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { createMenuItems } from '../../helpers/dropdown';
import MultiSelectDropdown from '../../components/MultiSelectDropdown/MultiSelectDropdown';
import { SuggestedDropdown } from '../../components/SuggestedDropdown/SuggestedDropdown';
import { Endpoint } from '../../enums/APIEndpointEnum';
import { actions as languageActions } from '../../store/slices/languagesSlice';
import { actions as articleTypesActions } from '../../store/slices/articleTypesSlice';
import { actions as employeesActions } from '../../store/slices/employeeListSlice';
import { actions as orderLineStatusesActions } from '../../store/slices/orderLineStatusesSlice';
import { useFormik } from 'formik';
import {
  datePickerStyles,
  formatFilters,
  isObjectWithValueAndLabel,
} from '../../helpers/filters';
import { isEmpty, isNil } from 'lodash';
import { PMOrderLinesFilters } from '../../types/pmTypes';
import { dateFormatter } from '../../helpers/timeHelpers';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { actions as contactPersonActions } from '../../store/slices/contactPersonsSlice';

const useStyles = makeStyles(() =>
  createStyles({
    mainWrapper: {
      '& .MuiBadge-dot': {
        display: 'none',
      },
      '& .hasUnreadMessages.MuiDataGrid-row': {
        '& .MuiBadge-dot': {
          display: 'block',
        },
      },
    },
    dropdownMenu: {
      maxHeight: '300px !important',
    },
    statusBox: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      '& .MuiOutlinedInput': {
        width: '100%',
        '& #statusUuids': {
          width: '100%',
        },
      },
    },
  }),
);
const PMTableFilters = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { filters, sort } = useSelector(
    (state: RootState) => state?.pmOrderLines,
  );
  const orderLineStatusesInitialData = useSelector(
    (state: any) => state?.orderLineStatuses?.orderLineStatuses,
  ).filter(
    (entry: any) =>
      entry.code === 'pm' ||
      entry.code === 'pm_check' ||
      entry.code === 'reject' ||
      entry.code === 'edit_done',
  );

  const contactPersonDropdown = useSelector(
    (state: RootState) => state?.contactPersons?.items,
  ).map((conPerson: any) => ({
    label: conPerson?.nick,
    value: conPerson?.uuid,
  }));

  const writersDropdown = useSelector(
    (state: RootState) => state?.orderLines?.writers,
  ).map((entry) => ({
    label: entry?.firstName + ' ' + entry.lastName,
    value: entry?.uuid,
  }));

  const editorsDropdown = useSelector(
    (state: RootState) => state?.orderLines?.editors,
  ).map((entry) => ({
    label: entry?.firstName + ' ' + entry.lastName,
    value: entry?.uuid,
  }));

  const assistantPMDropdown = useSelector(
    (state: RootState) => state?.employees?.employeesByRole,
  ).map((entry: any) => ({
    label: entry?.firstName + ' ' + entry.lastName,
    value: entry?.uuid,
  }));

  const languagesDropdown = useSelector(
    (state: RootState) => state?.languages?.items,
  ).map((entry) => ({ label: entry.name, value: entry.uuid }));

  const articleTypesDropdown = useSelector(
    (state: RootState) => state?.articleTypes?.articleTypes,
  ).map((entry) => ({ label: entry?.name, value: entry?.uuid }));

  const orderLineStatusesDropdown = useSelector(
    (state: any) => state?.orderLineStatuses?.orderLineStatuses,
  ).map((entry: any) => ({ label: entry.name, value: entry.uuid }));

  const [resetFilter, setResetFilter] = useState<boolean>(false);
  const [pmDueDateFromFormat, setPmDueDateFromFormat] = useState<any>(
    filters.pmDueDateFrom || null,
  );
  const [pmDueDateToFormat, setPmDueDateToFormat] = useState<any>(
    filters.pmDueDateTo || null,
  );
  const [firstLoading, setFirstLoading] = useState(true);

  const orderLineStatusesInitialDataUuids = orderLineStatusesInitialData.map(
    (item: any) => item.uuid,
  );

  const filterInitialValues = {
    articleTypeUuid: '',
    assistantPmUuid: '',
    daysOverdue: 'All',
    editorUuid: '',
    keyword: '',
    languageUuid: '',
    pmDueDateFrom: '',
    pmDueDateTo: '',
    sourceLanguageUuid: '',
    statusUuids: orderLineStatusesInitialDataUuids,
    writerUuid: '',
    contactPersonUuid: '',
  };

  const defaultValues: PMOrderLinesFilters = {
    keyword: filters.keyword || '',
    pmDueDateFrom: pmDueDateFromFormat,
    pmDueDateTo: pmDueDateToFormat,
    statusUuids: isNil(filters.statusUuids)
      ? orderLineStatusesInitialDataUuids
      : filters.statusUuids,

    daysOverdue: filters.daysOverdue || 'All',
    articleTypeUuid: filters.articleTypeUuid,
    sourceLanguageUuid: filters.sourceLanguageUuid,
    languageUuid: filters.languageUuid,
    assistantPmUuid: filters.assistantPmUuid,
    writerUuid: filters.writerUuid,
    editorUuid: filters.editorUuid,
    contactPersonUuid: filters.contactPersonUuid,
  };

  const { setValues, ...formik } = useFormik({
    initialValues: defaultValues,
    enableReinitialize: true,
    onSubmit: (values: PMOrderLinesFilters) => {
      const objForFilterFormatter = { ...values };
      objForFilterFormatter.sourceLanguageUuid =
        values?.sourceLanguageUuid?.value || null;
      objForFilterFormatter.languageUuid = values?.languageUuid?.value || null;
      objForFilterFormatter.assistantPmUuid =
        values?.assistantPmUuid?.value || null;
      objForFilterFormatter.writerUuid = values?.writerUuid?.value || null;
      // objForFilterFormatter.contactPersonUuid = values?.contactPersonUuid?.value || null;
      objForFilterFormatter.editorUuid = values?.editorUuid?.value || null;

      const formatValues = formatFilters(objForFilterFormatter);
      const objectForDropdownValues: any = {};
      let pmDueDateFrom = '';
      let pmDueDateTo = '';
      let statusUuids: any = [];
      if (pmDueDateFromFormat !== null) {
        pmDueDateFrom = moment(
          new Date(dateFormatter(pmDueDateFromFormat)),
        ).format('YYYY-MM-DDTHH:mm:ss.sssZ');
        objectForDropdownValues.pmDueDateFrom = pmDueDateFrom;
      }

      if (pmDueDateToFormat !== null) {
        pmDueDateTo = moment(new Date(dateFormatter(pmDueDateToFormat))).format(
          'YYYY-MM-DDTHH:mm:ss.sssZ',
        );
        objectForDropdownValues.pmDueDateTo = pmDueDateTo;
      }
      if (formik?.values?.statusUuids.length !== 0) {
        //params.statusUuids = formik?.values?.statusUuids;
        statusUuids = formik?.values?.statusUuids;
        objectForDropdownValues.statusUuids = statusUuids;
      }
      dispatch(
        pmOrderActions.setFiletDropdownValues({
          ...formatValues,
          ...objectForDropdownValues,
        }),
      );

      dispatch(
        pmOrderActions.setFilterParams({
          ...formatValues,
          ...objectForDropdownValues,
        }),
      );

      if (!isEmpty(formatValues)) {
        const params = {
          ...formatValues,
          ...sort,
          pmDueDateFrom,
          pmDueDateTo,
          statusUuids,
          limit: 50,
        };

         dispatch(pmOrderActions.sendProjectOrderLinesFilters(params));
      } else {
        dispatch(
          pmOrderActions.sendProjectOrderLinesFilters({ ...sort, limit: 50 }),
        );
      }

      dispatch(pmOrderActions.setProjectOrderLinesFilters(formik.values)); // function for saving filters when go to other page and return to this
    },
  });

  const handlepmDueDateFrom = (value: any, inputValue: any) => {
    setPmDueDateFromFormat(inputValue);
  };

  const handlepmDueDateTo = (value: any, inputValue: any) => {
    setPmDueDateToFormat(inputValue);
  };

  const onReset = () => {
    setResetFilter(true);
    setValues(filterInitialValues);
    formik.setFieldValue('statusUuids', orderLineStatusesInitialDataUuids);
    formik.setFieldValue('pmDueDateFrom', null);
    formik.setFieldValue('pmDueDateTo', null);

    const defaultFilters = { ...formik.initialValues };
    defaultFilters.pmDueDateFrom = null;
    defaultFilters.pmDueDateTo = null;

    dispatch(pmOrderActions.setProjectOrderLinesFilters(filterInitialValues));

    dispatch(
      pmOrderActions.setFiletDropdownValues({
        statusUuids: orderLineStatusesInitialDataUuids,
      }),
    );

    dispatch(
      pmOrderActions.setFilterParams({
        statusUuids: orderLineStatusesInitialDataUuids,
      }),
    );

    setPmDueDateFromFormat(null);
    setPmDueDateToFormat(null);
    dispatch(
      pmOrderActions.sendProjectOrderLinesFilters({
        ...sort,
        statusUuids: orderLineStatusesInitialDataUuids,
        limit: 50,
      }),
    );
  };

  useEffect(() => {
    const params: any = {
      urlSuffix: 'assistant_pm',
    };
    dispatch(languageActions.getLanguages());
    dispatch(articleTypesActions.getArticleTypes());
    dispatch(orderLinesActions.getOrderLinesWriters(''));
    dispatch(orderLinesActions.getOrderLinesEditors(''));
    dispatch(contactPersonActions.getContactPersonsList());
    dispatch(employeesActions.getEmployeesByRole(params));
    dispatch(orderLineStatusesActions.getOrderLineStatuses());
  }, [dispatch]);

  useEffect(() => {
    if (firstLoading && orderLineStatusesInitialDataUuids.length !== 0) {
      const params = { limit: 50 };
      Object.entries(formik.values).map(([key, value]) => {
        if (value !== '' && value !== 'All') {
          if (isObjectWithValueAndLabel(value)) {
            params[key] = value.value;
          } else {
            // @ts-ignore
            params[key] = value;
          }
        }
        if (key === 'statusUuids') {
          // @ts-ignore
          params[key] = value;
        }
        if (key === 'pmDueDateFrom' || key === 'pmDueDateTo')
          params[key] = dateFormatter(value);
      }); // get saved filters when go to other page and return to this
      dispatch(pmOrderActions.sendProjectOrderLinesFilters(params));
      setTimeout(() => {
        setFirstLoading(false);
      }, 800);
    }

    dispatch(
      pmOrderActions.setFiletDropdownValues({
        statusUuids: orderLineStatusesInitialDataUuids,
      }),
    ); // have default initial status value in this state for handle page & handleSort functions
  }, [formik.values.statusUuids]);

  return (
    <Filters handleFilters={formik.submitForm} onReset={onReset}>
      <Box display={'flex'} alignItems="center">
        <Box minWidth={'200px'} mr="15px">
          <FormLabel>Keyword</FormLabel>
          <TextField
            autoHeight
            fullWidth
            name="keyword"
            size="small"
            value={formik.values.keyword}
            onChange={formik.handleChange}
            autoComplete="off"
            error={Boolean(formik.errors.keyword)}
          />
        </Box>
        <Box minWidth={'200px'} mr="15px" alignSelf="baseline">
          <FormLabel>PM Due Date From</FormLabel>
          <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment}>
            <KeyboardDatePicker
              autoOk={true}
              showTodayButton={true}
              value={pmDueDateFromFormat}
              inputValue={
                defaultValues?.pmDueDateFrom
                  ? defaultValues?.pmDueDateFrom
                  : pmDueDateFromFormat
              }
              onChange={handlepmDueDateFrom}
              format="DD.MM.YYYY"
              style={datePickerStyles}
              clearable
            />
          </MuiPickersUtilsProvider>
        </Box>

        <Box minWidth={'200px'} mr="15px" alignSelf={'baseline'}>
          <FormLabel>PM Due Date To</FormLabel>
          <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment}>
            <KeyboardDatePicker
              autoOk={true}
              showTodayButton={true}
              value={pmDueDateToFormat}
              inputValue={
                defaultValues?.pmDueDateTo
                  ? defaultValues?.pmDueDateTo
                  : pmDueDateToFormat
              }
              onChange={handlepmDueDateTo}
              format="DD.MM.YYYY"
              style={datePickerStyles}
              clearable
            />
          </MuiPickersUtilsProvider>
        </Box>
        <Box minWidth={'200px'} mr="15px">
          <FormLabel>Days Overdue</FormLabel>
          <TextField
            select
            name="daysOverdue"
            size="small"
            value={formik.values.daysOverdue}
            onChange={formik.handleChange}
            sx={{ display: 'flex' }}
            SelectProps={{
              MenuProps: { PaperProps: { className: classes.dropdownMenu } },
            }}>
            {createMenuItems(daysOverdueDropdown())}
          </TextField>
        </Box>
        <Box
          minWidth={'200px'}
          maxWidth="200px"
          height="100%"
          mr="15px"
          className={classes.statusBox}>
          <FormLabel>Status</FormLabel>

          <MultiSelectDropdown
            initialData={orderLineStatusesDropdown}
            inputId="statusUuids"
            name="statusUuids"
            defaultValue={formik?.values?.statusUuids}
            inputValue={formik?.values?.statusUuids}
            formikHook={formik}
          />
        </Box>
        <Box minWidth={'200px'} mr="15px">
          <FormLabel>Article Type</FormLabel>
          <SuggestedDropdown
            filter
            url={Endpoint.ArticleTypes}
            inputId="articleTypeUuid"
            initialData={articleTypesDropdown}
            //   inputValue={formik?.values.languageUuid}
            inputValue={formik?.values.articleTypeUuid}
            dropdownLabel="name"
            dropdownValue="uuid"
            dataLocation="resultData"
            error={Boolean(formik.errors.articleTypeUuid)}
            helperText={formik.errors.articleTypeUuid}
            formikHook={formik}
            style={{ width: '100%' }}
            resetFilter={resetFilter}
          />
        </Box>
        <Box minWidth={'200px'} mr="15px">
          <FormLabel>Language</FormLabel>
          <SuggestedDropdown
            filter
            url={Endpoint.Languages}
            inputId="languageUuid"
            initialData={languagesDropdown}
            //   inputValue={formik?.values.languageUuid}
            inputValue={formik?.values.languageUuid}
            dropdownLabel="name"
            dropdownValue="uuid"
            dataLocation="resultDataItems"
            error={Boolean(formik.errors.languageUuid)}
            helperText={formik.errors.languageUuid}
            formikHook={formik}
            style={{ width: '100%' }}
            resetFilter={resetFilter}
            getWholeObjectAsValue
          />
        </Box>
        <Box minWidth={'200px'} mr="15px">
          <FormLabel>Source Language</FormLabel>
          <SuggestedDropdown
            filter
            url={Endpoint.Languages}
            inputId="sourceLanguageUuid"
            initialData={languagesDropdown}
            //   inputValue={formik?.values.sourceLanguageUuid}
            inputValue={formik?.values.sourceLanguageUuid}
            dropdownLabel="name"
            dropdownValue="uuid"
            dataLocation="resultDataItems"
            error={Boolean(formik.errors.sourceLanguageUuid)}
            helperText={formik.errors.sourceLanguageUuid}
            formikHook={formik}
            style={{ width: '100%' }}
            resetFilter={resetFilter}
            getWholeObjectAsValue
          />
        </Box>
        <Box minWidth={'200px'} mr="15px">
          <FormLabel>Assistant PM</FormLabel>
          <SuggestedDropdown
            filter
            url={Endpoint.AssistantPm}
            inputId="assistantPmUuid"
            initialData={assistantPMDropdown}
            inputValue={formik?.values.assistantPmUuid}
            dropdownLabel={['firstName', 'lastName']}
            dropdownValue="uuid"
            dataLocation="resultDataItems"
            error={Boolean(formik.errors.assistantPmUuid)}
            helperText={formik.errors.assistantPmUuid}
            formikHook={formik}
            getWholeObjectAsValue
            style={{ width: '100%' }}
            resetFilter={resetFilter}
          />
        </Box>
        <Box minWidth={'200px'} mr="15px">
          <FormLabel>Writer</FormLabel>
          <SuggestedDropdown
            filter
            url={Endpoint.OrderLinesWriters}
            inputId="writerUuid"
            initialData={writersDropdown}
            //   inputValue={formik?.values?.writerUuid}
            inputValue={formik?.values.writerUuid}
            dropdownLabel={['firstName', 'lastName']}
            dropdownValue="uuid"
            dataLocation="resultData"
            error={Boolean(formik.errors.writerUuid)}
            helperText={formik.errors.writerUuid}
            formikHook={formik}
            style={{ width: '100%' }}
            resetFilter={resetFilter}
            getWholeObjectAsValue
          />
        </Box>
        <Box minWidth={'200px'} mr="15px">
          <FormLabel>Editor</FormLabel>
          <SuggestedDropdown
            filter
            url={Endpoint.OrderLinesEditors}
            inputId="editorUuid"
            initialData={editorsDropdown}
            inputValue={formik?.values.editorUuid}
            dropdownLabel={['firstName', 'lastName']}
            dropdownValue="uuid"
            dataLocation="resultData"
            error={Boolean(formik.errors.editorUuid)}
            helperText={formik.errors.editorUuid}
            formikHook={formik}
            style={{ width: '100%' }}
            resetFilter={resetFilter}
            getWholeObjectAsValue
          />
        </Box>
        <Box minWidth={'200px'} mr="15px">
          <FormLabel>Contact Person</FormLabel>
          <SuggestedDropdown
            filter
            url={Endpoint.ContactPersons}
            inputId="contactPersonUuid"
            initialData={contactPersonDropdown}
            inputValue={formik?.values.contactPersonUuid}
            dropdownLabel={'nick'}
            dropdownValue="uuid"
            order="ASC"
            dataLocation="resultDataItems"
            error={Boolean(formik.errors.contactPersonUuid)}
            helperText={formik.errors.contactPersonUuid}
            formikHook={formik}
            style={{ width: '100%' }}
            resetFilter={resetFilter}
          />
        </Box>
      </Box>
    </Filters>
  );
};

export default memo(PMTableFilters);
