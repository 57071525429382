import { put, call, takeLatest } from 'redux-saga/effects';
import { actions as commonActions } from 'store/slices/common';
import {
  getAPLanguageRateAPI,
  addAPLanguageRateAPI,
  editAPLanguageRateAPI,
  deleteAPLanguageRateAPI,
} from 'store/api/apLanguageRateAPI';
import { PayloadAction } from '@reduxjs/toolkit';
import { APIResult } from 'types/APITypes';
import { actions as apLanguagesRateActions } from '../slices/apLanguageRateSlice';

function* getAPLanguageRates(action: PayloadAction<string>) {
  const params = action.payload;

  yield put(commonActions.setLoading(true));

  const { result } = yield call(getAPLanguageRateAPI, params);

  if (result?.data) {
    yield put(apLanguagesRateActions.setAPLanguageRates(result?.data));
  }
  yield put(commonActions.setLoading(false));
}

function* addAPLanguageRate(action: PayloadAction<any>) {
  const data = action.payload;

  const filters = action.payload?.filters || {};

  yield put(commonActions.setLoading(true));

  const { error }: APIResult<any> = yield call(addAPLanguageRateAPI, data);
  if (error) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: error.message,
        severity: 'error',
      }),
    );
  } else {
    const { result }: APIResult<any> = yield call(getAPLanguageRateAPI, {
      page: 1,
      ...filters,
    });
    if (result?.data) {
      yield put(apLanguagesRateActions.setAPLanguageRates(result.data));
    }
    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully added new item to the list`,
        severity: 'success',
      }),
    );
    yield put(commonActions.hideModal());
    yield put(commonActions.setLoading(false));
  }
}

function* editAPLanguageRate(action: PayloadAction<any>) {
  const { urlSuffix } = action.payload;
  const data = action.payload;
  const filters = action.payload?.filters || {};

  yield put(commonActions.setLoading(true));

  const { error }: APIResult<any> = yield call(
    editAPLanguageRateAPI,
    urlSuffix,
    data,
  );
  if (error) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: error.message,
        severity: 'error',
      }),
    );
  } else {
    const { result }: APIResult<any> = yield call(getAPLanguageRateAPI, {
      page: 1,
      ...filters,
    });
    if (result?.data) {
      yield put(apLanguagesRateActions.setAPLanguageRates(result.data));
    }
    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully edited this item`,
        severity: 'info',
      }),
    );
    yield put(commonActions.hideModal());
    yield put(commonActions.setLoading(false));
  }
}

function* deleteAPLanguageRate(action: PayloadAction<any>) {
  const { urlSuffix } = action.payload;

  yield put(commonActions.setLoading(true));

  const { error }: APIResult<any> = yield call(
    deleteAPLanguageRateAPI,
    urlSuffix,
  );
  if (error) {
    yield put(
      commonActions.snackBar({
        open: true,
        message: error.message,
        severity: 'error',
      }),
    );
  } else {
    const { result }: APIResult<any> = yield call(getAPLanguageRateAPI, {
      page: 1,
    });
    if (result?.data) {
      yield put(apLanguagesRateActions.setAPLanguageRates(result.data));
    }
    yield put(
      commonActions.snackBar({
        open: true,
        message: `You have successfully deleted this item from the list`,
        severity: 'error',
      }),
    );
    yield put(commonActions.hideModal());
    yield put(commonActions.setLoading(false));
  }
}

export default function* APLanguagesRateWatcher() {
  yield takeLatest(
    apLanguagesRateActions.getAPLanguageRates.type,
    getAPLanguageRates,
  );
  yield takeLatest(
    apLanguagesRateActions.addAPLanguageRate.type,
    addAPLanguageRate,
  );
  yield takeLatest(
    apLanguagesRateActions.editAPLanguageRate.type,
    editAPLanguageRate,
  );
  yield takeLatest(
    apLanguagesRateActions.deleteAPLanguageRate.type,
    deleteAPLanguageRate,
  );
}
