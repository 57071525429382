import {GridValueGetterParams} from '@mui/x-data-grid';
import l from 'helpers/l';
import moment from 'moment';

export const columns = [
  {
    field: 'id',
    headerName: 'id',
    width: 80,
  },
  {
    field: 'clientName',
    headerName: l('CONTACT_PERSONS.CLIENT_NAME'),
    minWidth: 150,
    valueGetter: (params: GridValueGetterParams) => params?.row?.client?.name,
  },
  {
    field: 'nick',
    headerName: l('CONTACT_PERSONS.NICK_NAME'),
    minWidth: 250,
    valueGetter: (params: GridValueGetterParams) => params?.row?.nick,
  },
  {
    field: 'firstName',
    headerName: l('COMMON.FIRSTNAME'),
    minWidth: 250,
    valueGetter: (params: GridValueGetterParams) => params?.row?.firstName,
  },
  {
    field: 'lastName',
    headerName: l('COMMON.LASTNAME'),
    minWidth: 250,
  },
  {
    field: 'email',
    headerName: l('COMMON.EMAIL'),
    minWidth: 250,
  },
  {
    field: 'phone',
    headerName: l('COMMON.PHONE'),
    minWidth: 250,
  },
  {
    field: 'accountManager',
    headerName: l('CONTACT_PERSONS.ACCOUNT_MANAGER'),
    minWidth: 250,
    valueGetter: (params: GridValueGetterParams) =>
      `${params?.row?.accountManager?.firstName || ''} ${params?.row?.accountManager?.lastName || ''}`,
  },
  {
    field: 'notes',
    headerName: l('NOTES'),
    minWidth: 250,
  },
  {
    field: 'createdUpdatedAt',
    headerName: l('CONTACT_PERSONS.CREATED_UPDATED_AT'),
    minWidth: 250,
    valueGetter: (params: GridValueGetterParams) =>
      `${moment(params.row.createdAt).format('DD.MM.YYYY hh:mm')} /
       ${moment(params.row.updatedAt).format('DD.MM.YYYY hh:mm')}`,
  },
];
