import {FC, memo, useCallback} from 'react';
import {useDispatch} from 'hooks/redux';
import {GenericModalProps} from 'types/genericTypes';
import ReportCategoriesForm from "../../../Forms/reports/ReportCategoriesForm";
import {actions as reportCategoriesActions} from "../../../../store/slices/reportSlices/reportCategoriesSlice";

const ReportCategoryAddModal: FC<GenericModalProps> = ({data, ...rest}) => {
    const dispatch = useDispatch();
    const submitHandler = useCallback(
        (values: any) => {
            if (data) {
                const urlSuffix = data.uuid;
                const objToSend = {
                    data: values,
                    urlSuffix: urlSuffix,
                }
                dispatch(reportCategoriesActions.editReportCategory(objToSend));
            } else {
                dispatch(reportCategoriesActions.postReportCategory(values));
            }
        },
        [dispatch],
    );
    const initValues = {
        name: data?.name,
    }

    return (
        <ReportCategoriesForm
            title={`Report Category ${data ? 'Edit' : 'Add'}`}
            onSubmit={submitHandler}
            initialValues={data ? initValues : undefined }
            {...rest}
        />
    );
};

export default memo(ReportCategoryAddModal);
