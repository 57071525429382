import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PageLayout from 'components/PageLayout/PageLayout';
import EditTable from 'components/Table/EditTable';
import l from 'helpers/l';
import { GridRenderCellParams, GridRowParams, GridValueGetterParams } from '@mui/x-data-grid';
import { RootState } from 'store';
import { actions as messagesActions } from 'store/slices/messagesSlice';
import { actions as orderLinesActions } from 'store/slices/orderLinesSlice';
import { Box, Button, Menu, MenuItem, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { MainWrapperDiv } from './MessagesStyles';
import { getFullName } from 'helpers/text';
import { formatTime } from 'helpers/timeHelpers';
import { useFormik } from 'formik';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  AccountBoxRounded as AccountBoxRoundedIcon,
  Close as CloseIcon,
  PriorityHigh as PriorityHighIcon,
} from '@mui/icons-material';
import ConversationInfoAccordion from 'components/MessageComponents/ConversationInfoAccordion';
import { removeDuplicates } from 'config/utilsFunctions';
import NotifyList from 'components/MessageComponents/NotifyList';

const initialValues = {
  messageText: '',
  accountManagerUuid: false,
  receivedByUuid: false,
  projectManagerUuid: false,
  assistantProjectManagerUuid: false,
  writerUuid: false,
  editorUuid: false,
};

const MessagesPage = () => {
  // const classes = useStyles();
  const dispatch = useDispatch();
  useNavigate();
  const location = useLocation();
  useSelector((state: RootState) => state.common.loading);
  const { items, meta } = useSelector((state: RootState) => state.messages);
  const { currentConversation } = useSelector(
    (state: RootState) => state.messages,
  );
  const currentUser = useSelector((state: RootState) => state.auth.user);
  const currentOrderLine = useSelector(
    (state: RootState) => state.orderLines.item,
  );

  const [conversationInfo, setConversationInfo] = useState<any>({});
  const [quotedMessageUuid, setQuotedMessageUuid] = useState<string>('');
  const [quotedMessageText, setQuotedMessageText] = useState<string>('');
  const [quoteReply, setQuoteReply] = useState<boolean>(false);

  const [messageMenuOptions, setMessageMenuOptions] =
    useState<null | HTMLElement>(null);

  const [, setPageState] = useState<number>(1);

  const isMessageMenuOpen = Boolean(messageMenuOptions);

  const handleMessageMenuOpen = (
    event: React.MouseEvent<HTMLElement>,
    conv?: any,
  ) => {
    setMessageMenuOptions(event.currentTarget);
    setQuotedMessageUuid(conv?.uuid);
    setQuotedMessageText(conv?.body);
  };

  const handleDiscardReplyMessage = () => {
    setQuotedMessageText('');
    setQuotedMessageUuid('');
    setQuoteReply(false);
  };

  const handleQuoteReply = () => {
    setQuoteReply(true);
    setMessageMenuOptions(null);
  };
  const handleMessageMenuClose = () => {
    setMessageMenuOptions(null);
    setQuotedMessageUuid('');
    setQuoteReply(false);
  };
  const sendNewMessage = async (values: any) => {
    const data: any = {
      body: values.messageText,
      employeeUuidsToNotify: [] as any,
      orderLineUuid: conversationInfo?.orderLineUuid,
    };
    const employeeUuidsToNotifyArray: string[] = [];
    if (values.accountManagerUuid) {
      employeeUuidsToNotifyArray.push(conversationInfo?.accountManager.uuid);
    }
    if (values.assistantProjectManagerUuid) {
      employeeUuidsToNotifyArray.push(
        conversationInfo?.assistantProjectManager.uuid,
      );
    }
    if (values.projectManagerUuid) {
      employeeUuidsToNotifyArray.push(conversationInfo?.projectManager.uuid);
    }
    if (values.receivedByUuid) {
      employeeUuidsToNotifyArray.push(conversationInfo?.receivedBy.uuid);
    }
    if (values.editorUuid) {
      employeeUuidsToNotifyArray.push(conversationInfo?.editor.uuid);
    }
    if (values.writerUuid) {
      employeeUuidsToNotifyArray.push(conversationInfo?.writer.uuid);
    }
    if (quotedMessageUuid !== '') {
      data.quotedMessageUuid = quotedMessageUuid;
    }
    data.employeeUuidsToNotify = removeDuplicates(
      employeeUuidsToNotifyArray,
    );
    await dispatch(messagesActions.sendNewMessage(data));

    onReset();
    handleDiscardReplyMessage();
    scrollToBottom(false);

    setTimeout(() => {
      dispatch(
        messagesActions.getAllMessagesInConversation({
          urlSuffix: conversationInfo?.orderLineUuid,
        }),
      );
    }, 700);
  };

  const { setValues, ...formik } = useFormik({
    initialValues,
    validateOnChange: false,
    onSubmit: async (values: any) => {
      const params = {
        page: 1,
      };
      dispatch(messagesActions.getAllMessageConversations(params));
      await sendNewMessage(values);
    },
  });

  const onReset = useCallback(() => {
    setValues(initialValues);
  }, [setValues]);

  const messagesEndRef = useRef<any>(null);

  const scrollToBottom = (firstEntry: boolean) => {
    if (firstEntry) {
      setTimeout(() => {
        messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
      }, 1500);
    } else {
      setTimeout(() => {
        messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
      }, 500);
    }
  };

  const handleClassName = (params: GridRowParams) => {
    let className = '';
    if (params.id === conversationInfo?.orderLineUuid) {
      className = ' selectedRow';
    }
    if (params?.row?.hasUnseenMessages) {
      className += ' hasUnreadMessages';
    }
    return className;
  };

  const handlePage = useCallback(
    (page: number) => {
      const params = { page: page + 1 };
      setPageState(params.page);
      dispatch(messagesActions.getAllMessageConversations(params));
    },
    [dispatch],
  );

  const handleCurrentConversation = (params: GridRowParams) => {
    const conversationInfoValues = items.find(
      (item) => item.orderLineUuid === params?.row.orderLineUuid,
    );
    setConversationInfo(conversationInfoValues);
    // handleClassName('selected');
    scrollToBottom(false);
    dispatch(
      messagesActions.getAllMessagesInConversation({
        urlSuffix: params?.row.orderLineUuid,
      }),
    );
    handleUnseenMessagesMarkSeen(params?.row.orderLineUuid);
  };

  const handleUnseenMessagesMarkSeen = (orderLineUuid: string) => {
    dispatch(
      messagesActions.unseenMessagesMarkSeen({
        urlSuffix: orderLineUuid,
      }),
    );
  };

  useEffect(() => {
    if (location.state !== null) {
      const data = {
        orderLineUuid: location?.state,
      };
      dispatch(orderLinesActions.getOrderLine(data));
    }
    if (conversationInfo?.uuid) {
      dispatch(
        orderLinesActions.getOrderLine({
          orderLineUuid: conversationInfo?.uuid,
        }),
      );

      //   dispatch(messagesActions.getAllMessageConversations(params));
    } else {
      const params = {
        page: 1,
      };
      dispatch(messagesActions.getAllMessageConversations(params));
    }
    // dispatch(messagesActions.setClickedMessages([]));
  }, [dispatch]);

  useEffect(() => scrollToBottom(true), []);

  useEffect(() => {
    setTimeout(() => {
      if (location.state !== null || conversationInfo?.uuid) {
        dispatch(
          messagesActions.getAllMessagesInConversation({
            urlSuffix:
              location.state !== null ? location.state : conversationInfo?.uuid,
          }),
        );
        const myObjForConversationInfo = {
          accountManager: {
            uuid: currentOrderLine?.order?.contactPerson?.accountManager?.uuid,
            firstName:
            currentOrderLine?.order?.contactPerson?.accountManager?.firstName,
            lastName:
            currentOrderLine?.order?.contactPerson?.accountManager?.lastName,
          },
          articleTopic: currentOrderLine?.articleTopic,
          assistantProjectManager: currentOrderLine?.assistantProjectManager,
          contactPerson: {
            uuid: currentOrderLine?.order?.contactPerson?.uuid,
            firstName: currentOrderLine?.order?.contactPerson?.firstName,
            lastName: currentOrderLine?.order?.contactPerson?.lastName,
            nick: currentOrderLine?.order?.contactPerson?.nick,
          },
          editor: currentOrderLine?.editor,
          languageName: currentOrderLine?.language?.name,
          //   hasPriorityMessages: true,
          //   hasUnseenMessages: true,
          //   lastMessageSentAt: '2022-04-20T12:36:59.509Z',
          lineNumber: currentOrderLine?.lineNumber,
          orderId: currentOrderLine?.order?.id,
          orderLineUuid: currentOrderLine?.uuid,
          projectManager: {
            uuid: currentOrderLine?.order?.receivedBy?.uuid,
            firstName: currentOrderLine?.order?.receivedBy?.firstName,
            lastName: currentOrderLine?.order?.receivedBy?.lastName,
          },
          receivedBy: {
            uuid: currentOrderLine?.order?.receivedBy?.uuid,
            firstName: currentOrderLine?.order?.receivedBy?.firstName,
            lastName: currentOrderLine?.order?.receivedBy?.lastName,
          },
          writer: currentOrderLine?.writer,
        };
        // @ts-ignore
        handleUnseenMessagesMarkSeen(location.state);
        setConversationInfo(myObjForConversationInfo);
      }

      if (items && location.state === null) {
        dispatch(
          messagesActions.getAllMessagesInConversation({
            urlSuffix: items[0]?.orderLineUuid,
          }),
        );
        setConversationInfo(items[0]);
        handleUnseenMessagesMarkSeen(items[0]?.orderLineUuid);
      }
    }, 600);
  }, [items, location, currentOrderLine]);

  return (
    <PageLayout title={l('PAGES.MESSAGES')} fullWidth messageScreen>
      <MainWrapperDiv>
        <Box width={'49%'}>
          <EditTable
            getRowId={(row) => row.orderLineUuid}
            rows={items}
            editColumnWidth={2}
            columns={[
              {
                field: 'contactPerson',
                headerName: 'Contact Person',
                width: 150,
                sortable: false,
                // valueGetter: (params: GridValueGetterParams) =>
                //   params.row.contactPerson.nick,
                renderCell: (params: GridRenderCellParams) => (
                  <>
                    <Typography style={{ display: 'flex' }}>
                      <span
                        style={{
                          width: '25px',
                          display: 'flex',
                        }}>
                        {params.row.hasPriorityMessages ? (
                          <PriorityHighIcon color="error" />
                        ) : (
                          ''
                        )}
                      </span>
                      {params.row.contactPerson.nick}
                    </Typography>
                  </>
                ),
              },
              {
                field: 'orderId',
                headerName: 'Order #',
                width: 80,
                sortable: false,
              },
              {
                field: 'lineNumber',
                headerName: 'Line #',
                width: 80,
                sortable: false,
              },
              {
                field: 'articleTopic',
                headerName: 'Topic',
                width: 140,
                sortable: false,
              },
              {
                field: 'languageName',
                headerName: 'Language',
                width: 120,
                sortable: false,
              },
              {
                field: 'lastMessageSentAt',
                headerName: 'Last Message at',
                width: 180,
                sortable: false,
                valueGetter: (params: GridValueGetterParams) =>
                  formatTime(params.row.lastMessageSentAt),
              },
            ]}
            sx={{
              '& .MuiDataGrid': {
                '&$selected': {
                  backgroundColor: '#0f0',
                },
              },
            }}
            getRowClassName={handleClassName}
            // isRowSelectable
            onRowClick={handleCurrentConversation}
            pagination
            onPageChange={handlePage}
            // @ts-ignore
            page={meta?.currentPage - 1 || 0}
            rowCount={meta?.totalItems}
            pageSize={meta?.itemsPerPage}
            rowsPerPageOptions={[meta.itemsPerPage || 10]}
            paginationMode="server"
          />
        </Box>
        <Box className="currentConversation">
          <ConversationInfoAccordion
            conversationInfo={conversationInfo}
            currentUser={currentUser}
          />

          <Box className="messages">
            {currentConversation.map((conv) => (
              <Box
                className={`messageBox ${
                  currentUser?.employee?.uuid === conv?.sentBy?.uuid
                    ? 'my'
                    : 'their'
                }`}>
                <Box className="topPart">
                  <Box>
                    <AccountBoxRoundedIcon />
                    <Typography
                      sx={{
                        margin: '0 10px',
                        fontWeight: 600,
                        color:
                          currentUser?.employee?.uuid === conv?.sentBy?.uuid
                            ? '#F59A23'
                            : 'inherit',
                      }}>
                      {currentUser?.employee?.uuid === conv?.sentBy?.uuid
                        ? 'me'
                        : getFullName(conv?.sentBy)}
                    </Typography>
                    <Typography>{formatTime(conv?.createdAt)}</Typography>
                  </Box>
                  <span
                    aria-controls={'messageOption'}
                    onClick={(e) => handleMessageMenuOpen(e, conv)}>
                    <MoreVertIcon />
                  </span>
                </Box>
                {conv.quotedMessage !== null && (
                  <Box className="reply">
                    <Box display="flex" alignItems="center">
                      <AccountBoxRoundedIcon sx={{ fill: '#aaa' }} />
                      <Typography
                        sx={{
                          margin: '0 10px',
                          fontWeight: 600,
                          color: '#aaa',
                        }}>
                        {getFullName(conv?.quotedMessage.sentBy)}
                      </Typography>
                    </Box>
                    <Typography>{conv?.quotedMessage.body}</Typography>
                  </Box>
                )}
                <Box className="body">
                  <Typography>{conv?.body}</Typography>
                </Box>
              </Box>
            ))}
            <div ref={messagesEndRef}></div>
          </Box>
          <Box className="writeMessageBox">
            <Typography sx={{ marginBottom: '5px', fontWeight: 600 }}>
              {quotedMessageText !== '' && quoteReply ? 'Reply for' : 'Message'}
            </Typography>
            {quotedMessageText !== '' && quoteReply && (
              <Box
                bgcolor="#f4f4f4"
                padding="5px 8px"
                display="flex"
                borderRadius="15px"
                marginBottom="7px"
                justifyContent="space-between">
                <Typography style={{ fontStyle: 'italic' }}>
                  {quotedMessageText.length > 100
                    ? quotedMessageText.substring(0, 100).concat('...')
                    : quotedMessageText}
                </Typography>
                <span
                  onClick={handleDiscardReplyMessage}
                  style={{
                    fontSize: '16px',
                    cursor: 'pointer',
                    marginLeft: '7px',
                  }}>
                  <CloseIcon sx={{ fontSize: '16px' }} />
                </span>
              </Box>
            )}

            <NotifyList
              conversationInfo={conversationInfo}
              formik={formik}
              currentUser={currentUser}
            />

            <Box display="flex" justifyContent="flex-end">
              <Button
                onClick={formik.submitForm}
                disabled={formik.values.messageText === ''}
                className="sendMessageButton">
                Send
              </Button>
            </Box>
          </Box>
        </Box>
        </MainWrapperDiv>
      <Menu
        anchorEl={messageMenuOptions}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        id={'messageOption'}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={isMessageMenuOpen}
        onClose={handleMessageMenuClose}>
        <MenuItem>
          <Typography onClick={handleQuoteReply}>Quote reply</Typography>
        </MenuItem>
      </Menu>
    </PageLayout>
  );
};

export default memo(MessagesPage);
