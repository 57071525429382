export enum Endpoint {
  Currencies = '/currencies',
  TimeZones = '/timezones',
  AuthMe = '/auth/me',
  AuthLogin = '/auth/login',
  NewPassword = '/users/request-reset-password',
  ResetPassword = '/users/reset-password',
  SetPassword = '/users/set-password',
  Languages = '/languages',
  Countries = '/countries',
  Roles = '/roles',
  Replacements = '/replacement',
  AssistantPm = '/employees/roles/assistant_pm',
  TeamLeads = '/employees/roles/team_lead',
  AddReplacements = '/replacement/assign',
  UserLanguages = '/employee-language/assigned-languages',
  UserNotAssignedLanguages = '/employee-language/not-assigned-languages',
  UserExpertise = '/employee-expertise/assigned-expertises',
  UserNotAssignedExpertise = '/employee-expertise/not-assigned-expertises',
  UserQuestions = '/employee-question/assigned-questions',
  UserNotAssignedQuestions = '/employee-question/not-assigned-questions',
  PreferredEmployee = 'preferred-employee',
  PreferredEmployeeByEmployee = 'preferred-employee/by-employee',
  AddUserLanguage = '/employee-language',
  EditUserLanguage = '/employee-language',
  AddUserExpertise = '/employee-expertise',
  AddUserQuestion = '/employee-question',
  SetUsername = '/users/set-username',
  EmployeeReplacementsRoles = '/employees/roles',
  EmployeesList = '/employees',
  EmployeeStatuses = '/employee-statuses',
  Questions = '/questions',
  User = '/users',
  InactivateUser = '/users/inactivate',
  ActivateUser = '/users/activate',
  UnlockUser = '/users/unlock',
  RolesPermissions = '/roles/assign',
  Expertise = '/expertise',
  Voices = '/tones-of-voice',
  Payment = '/payment-methods',
  TargetAudience = '/target-audience',
  ArticleTypes = '/article-types',
  OrderTypes = '/order-types',
  OrderLineStatuses = '/order-line-statuses',
  OrderLineStatusesManage = '/order-line-statuses/manage',
  OrderLineStatusTransition = 'order-line-status-transition',
  OrderLineStatusTransitionNotAssigned = 'order-line-status-transition/not-assigned',
  OrderLineStatusTransitionAssigned = 'order-line-status-transition/assigned',
  OrderLineStatusRoles = '/order-line-status-roles',
  OrderLinesDelivery = '/order-lines-delivery',
  Checklists = '/checklist',
  ChecklistsRoles = '/roles_article-types_checklists',
  ChecklistsContactPersons = '/contact-persons_roles_article-types_checklists',
  EmployeeRoles = '/employee-roles',
  EmployeeAssignedRoles = '/employee-roles/assigned-roles',
  EmployeeAssignRoles = '/employee-roles/assign',
  EmployeeUnassign = '/employee-roles/unassign',
  Speakers = '/speakers',
  Permissions = '/permissions',
  PermissionsAssign = '/roles/assign',
  PermissionsRemove = '/roles/remove',
  EmailTemplates = '/email-templates',
  ContactPersons = '/contact-person',
  Clients = '/clients',
  Projects = '/projects',
  OrderEntries = '/orders',
  OrderLines = '/order-lines',
  DuplicateOrderLines = '/order-lines/duplicate',
  CancelAllOrderLines = '/order-lines/cancel',
  PmCheckList = '/order-line-checklists',
  PostPmCheckList = '/order-line-checklists',
  WriterCheckList = '/order-line-checklists/writer',
  EditorCheckList = '/order-line-checklists/editor',
  PostWriterCheckList = '/order-line-checklists/writer',
  PostEditorCheckList = '/order-line-checklists/editor',
  OrderLinesWriters = '/order-lines/employees/writers',
  OrderLinesWritersPage = '/order-lines-writers',
  OrderLinesEditors = '/order-lines/employees/editors',
  OrderLinesEditorsPage = '/order-lines-editors',
  EmployeesOrderPms = '/employees/orders/pms',
  OrderLinesOrder = '/order-lines/order',
  OrderLinesDuplicateFrom = '/order-lines/duplicate/from',
  OrderLinesPM = '/order-lines/pm',
  OrderLineStatusLog = '/order-line-status-log',
  Suggestion = '/suggestion',
  ReportParameters = '/reports/parameters',
  ReportParametersTables = '/reports/database/tables',
  ReportParametersColumns = '/reports/database/columns',
  DeleteReportParameter = '/reports/parameters',
  EditReportParameter = '/reports/parameters',
  Messaging = '/messaging',
  MessagingMessages = '/messaging/messages',
  MessagingThreads = '/messaging/threads',
  UnseenMessagesMarkSeen = '/unseen-messages/mark-seen', // mark messages in thread as seen
  UnseenMessagesHasUnseenMessages = '/unseen-messages/has-unseen-messages', // check if employee has any unseen messages
  UnseenMessagesUnseenOrderLines = '/unseen-messages/unseen-order-lines', // check if employee has any unseen messages
  UnseenMessages = '/unseen-messages',
  ReportCategories = '/reports/folders/all',
  AssignRoleToReportCategory = '/reports/folders/assign',
  UnassignFromPostReportCategory = '/reports/folders/unassign',
  CrudReportCategory = '/reports/folders',
  ExecuteReport = '/reports/report',
  DeleteReportExecution = '/reports/report-execution',
  PreviousExecutionsOfReport = '/reports/report-execution/my',
  SaveOfReport = '/reports/report-execution',
  ExportXLSX = '/reports/export/xlsx',
  ExportPDF = '/reports/export/pdf',
  ReportRunQuery = '/reports/database/query-reference-parameter',
  LanguageRate = '/language-rate',
  ProjectRate = '/project-rate',
  ClientRate = '/client-rate',
  APLanguageRate = '/ap-language-rate',
  APEmployeeLanguageRate = '/ap-employee-language-rate',
  APEmployeeProjectRate = '/ap-employee-project-rate',
  RunTestQuery = '/reports/database/run-query',
}

export enum Method {
  Get = 'get',
  Post = 'post',
  Put = 'put',
  Delete = 'delete',
  Patch = 'patch',
}
