import { FC, useCallback } from 'react';
import { useDispatch } from 'hooks/redux';
import { GenericModalProps } from 'types/genericTypes';
import { actions } from 'store/slices/common';
import l from '../../../helpers/l';
import OrderDeliveryForm from '../../Forms/OrderDeliveryForm';
import { actions as orderLinesDelivery } from '../../../store/slices/orderLinesDeliverySlice';
import { useSelector } from 'react-redux';

const OrderDeliveryEditModal: FC<GenericModalProps> = ({ data }) => {
  const dispatch = useDispatch();

  const filters = useSelector(
    (state: any) => state?.orderLinesDelivery?.filters,
  );

  const closeHandler = useCallback(
    () => dispatch(actions.hideModal()),
    [dispatch],
  );

  const initialValues = {
    orderLineStatusUuid: data?.lastStatusLogEntry?.orderLineStatus?.uuid,
    statusDescription: data?.lastStatusLogEntry?.description,
    clientCleanArticleLink: data?.clientCleanArticleLink,
    clientRevisionUrl: data?.clientRevisionUrl,
    orderUuid: data?.uuid,
    orderReceiverGradeForEditor: data?.orderReceiverGradeForEditor,
    orderReceiverGradeForPm: data?.orderReceiverGradeForPm,
  };

  const submitHandler = useCallback(
    (values: any) => {
      dispatch(
        orderLinesDelivery.editOrderLinesDelivery({ payload: values, filters }),
      );
    },
    [dispatch],
  );

  return (
    <OrderDeliveryForm
      title={l('ORDER_DELIVERY.TITLE')}
      onSubmit={submitHandler}
      initialValues={initialValues}
      data={data}
      onClose={closeHandler}
    />
  );
};

export default OrderDeliveryEditModal;
