import { Autocomplete, TextField } from '@mui/material';
import { Endpoint, Method } from 'enums/APIEndpointEnum';
import React, { useCallback, useEffect, useState } from 'react';
import apiCall from 'store/api/apiCall';
import { resultDataMapping } from './helpers';
import { loadingText } from 'constants/globalVariables';

interface Dropdown {
  label: string;
  value?: string;
}

interface SuggestedDropdownProps {
  url: Endpoint;
  urlSuffix?: any;
  inputId: string;
  inputValue?: any;
  defaultValue?: any;
  value?: any;
  initialData?: Dropdown[] | [];
  dropdownLabel: string | string[];
  dropdownValue: string;
  orderBy?: string;
  order?: 'ASC' | 'DESC';
  dataLocation: 'resultData' | 'resultDataItems';
  formikHook?: any;
  getWholeObjectAsValue?: boolean;
  style?: any;
  error?: any;
  helperText?: any;
  excludeEmployeeUuid?: string;
  filter?: boolean;
  resetFilter?: boolean;
  reportParameter?: any;
}

export const SuggestedDropdown = (props: SuggestedDropdownProps) => {
  // noinspection JSUnusedLocalSymbols
  const {
    url,
    urlSuffix,
    initialData,
    dropdownLabel,
    dropdownValue,
    dataLocation,
    orderBy,
    order,
    inputId,
    inputValue,
    defaultValue,
    value,
    formikHook,
    getWholeObjectAsValue = false,
    style,
    error,
    helperText,
    excludeEmployeeUuid,
    filter,
    resetFilter,
    reportParameter,
  } = props;

  //  const [menuItems, setMenuItems] = useState(initialData);
  const menu = initialData ? [...initialData] : [];

  const [menuItems, setMenuItems] = useState(menu);

  const [selectedItem, setSelectedItem] = useState(inputValue);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [, updateState] = useState<object>();

  const forceUpdate = useCallback(() => updateState({}), []);

  // noinspection JSUnusedLocalSymbols
  const onInputChangeHandler = useCallback(
    (event: React.SyntheticEvent, value: any, reason: string) => {
      if (value.length > 0 || !initialData?.length) {
        (async () => {
          setIsLoading(true);
          //@ts-ignore
          const params: any = { keyword: event?.target?.value };
          if (orderBy) params.orderBy = orderBy;
          if (order) params.order = order;
          if (excludeEmployeeUuid) {
            params.excludeEmployeeUuid = excludeEmployeeUuid;
          }
          if(reportParameter) params.parameterUuid = reportParameter.parameter.uuid;
          const result = await apiCall({
            url,
            method: Method.Get,
            urlSuffix: urlSuffix,
            params: params,
          });
          if (result?.result?.data) {
            setMenuItems(
              resultDataMapping(
                result,
                dataLocation,
                dropdownLabel,
                dropdownValue,
              ),
            );
          }
          setIsLoading(false);
        })();
        if (formikHook?.setFieldValue && !reportParameter) {
          formikHook.setFieldValue(
            inputId,
            getWholeObjectAsValue ? menuItems?.find((item: any) => item.label == value) : menuItems?.find((item: any) => item.label == value)?.value,
          );
        }
        if(formikHook?.setFieldValue && reportParameter){
          if(reportParameter.parameter.name && reportParameter.parameter.type){
            formikHook.setFieldValue(
                inputId,
                {
                  reportParameterUuid: reportParameter.uuid,
                  value: value,
                  parameterOptions:{
                    parameterType: reportParameter.parameter.type,
                    parameterName: reportParameter.parameter.name,
                  },
                },
            );
          }else{
            formikHook.setFieldValue(
                inputId,
                {
                  uuid: menuItems?.find((item: any) => item.label == value)?.value,
                  value: value,
                },
            );
          }

        }
      } else {
        (async () => {
          await clearInput();
        })();
      }
    },
    [menuItems],
  );

  const onChangeHandler = useCallback(
    async (event: React.SyntheticEvent, value: any, reason: string) => {
      if (reason === 'clear') {
        await clearInput();
      } else if (reason === 'selectOption') {
        setSelectedItem(value);
      }
    },
    [],
  );

  useEffect(() => {
    if (resetFilter) {
      clearSelectedValue();
      forceUpdate();
    }
  }, [resetFilter]);

  const onOpenHandler = useCallback(() => {
    (async () => {
      setIsLoading(true);
      const params: any = {};

      if (excludeEmployeeUuid) {
        params.excludeEmployeeUuid = excludeEmployeeUuid;
      }
      if (order) params.order = order;
      if(reportParameter) params.parameterUuid = reportParameter.parameter.uuid;
      const result = await apiCall({
        url,
        method: Method.Get,
        urlSuffix: urlSuffix,
        params: params,
      });
      if (result?.result?.data) {
        setMenuItems(
          resultDataMapping(result, dataLocation, dropdownLabel, dropdownValue),
        );
      }
      setIsLoading(false);
    })();
  }, []);

  const onCloseHandler = async (
    event: React.SyntheticEvent,
    reason: string,
  ) => {
    if (
      reason === 'blur' &&
      !(event.target as HTMLInputElement).value &&
      menuItems?.length !== 10
    ) {
      await clearInput();
    }
  };

  const clearSelectedValue = () => {
    setSelectedItem({ label: '', value: '' });
    formikHook.setFieldValue(inputId, filter ? '' : null);
  };

  async function clearInput() {
    const params: any = { keyword: '' };
    if (orderBy) params.orderBy = orderBy;
    if (order) params.order = order;

    if (excludeEmployeeUuid) {
      params.excludeEmployeeUuid = excludeEmployeeUuid;
    }

    if(reportParameter) params.parameterUuid = reportParameter.parameter.uuid;

    const result = await apiCall({
      url,
      method: Method.Get,
      urlSuffix: urlSuffix,
      params: params,
    });

    setMenuItems(
      resultDataMapping(result, dataLocation, dropdownLabel, dropdownValue),
    );
    clearSelectedValue();
  }
  return (
    <Autocomplete
      options={menuItems}
      renderInput={(params: any) => {
        return (
          <TextField
            {...params}
            autoComplete="off"
            error={error}
            helperText={helperText}
          />
        );
      }}
      //open
      loading={isLoading}
      loadingText={loadingText}
      sx={{ width: '100%', margin: '0' }}
      size={'medium'}
      id={inputId}
      // defaultValue={defaultValue || value}
      value={selectedItem || (value ? value : null)}
      getOptionLabel={(option) => option.label || ''}
      isOptionEqualToValue={(option, value) => option.value == value.value}
      style={style}
      // onClick={onInputChangeHandler}
      onInputChange={onInputChangeHandler}
      onChange={onChangeHandler}
      onOpen={onOpenHandler}
      onClose={onCloseHandler}
    />
  );
};
